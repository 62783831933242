<script setup lang="ts">
/**
 * @author Giorgi Tseradze
 */

//
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/vue";
import { computed, ref, watch } from "vue";
import { useMainStore } from "@/store/main/index";

import type { ImageType } from "@/store/main/types/component/UI";
import type { SecondaryDataInterface } from "@/types/ContentType";
const Picture = defineAsyncComponent(
  () => import("@/components/regularComponents/UIComponents/Picture.vue")
);

import LeftArrowSVG from "./SVG/LeftArrowSVG.vue";
import RightArrowSVG from "./SVG/RightArrowSVG.vue";

const props = defineProps<{
  contentData?: SecondaryDataInterface;
  images?: Partial<ImageType>[];
}>();

const mainStore = useMainStore();
const swiperInstance = ref();
const dragOn = ref(false);

const getSwiperInstance = (sw: any) => {
  swiperInstance.value = sw;

  sw.on("touchMove", () => {});

  sw.on("touchStart", () => {
    sw.slides.forEach((slide: HTMLElement) =>
      slide.classList.add("swiper-slide--grabbed")
    );
    dragOn.value = true;
  });

  sw.on("touchEnd", () => {
    sw.slides.forEach((slide: HTMLElement) =>
      slide.classList.remove("swiper-slide--grabbed")
    );
    dragOn.value = false;
  });
};

const lgSpaceBetween = ref();
const mdSpaceBetween = ref();
const smSpaceBetween = ref();

const computedWidth = computed(() => mainStore?.windowWidth);

watch(computedWidth, (n) => {
  lgSpaceBetween.value = (272 / n) * 100;
  mdSpaceBetween.value = (190 / n) * 100;
  smSpaceBetween.value = (80 / n) * 100;
});
</script>

<template>
  <section class="gumbati-slider">
    <div class="gumbati-slider__inner pos-rel">
      <Swiper
        :slidesPerView="'auto'"
        @swiper="getSwiperInstance"
        :key="mainStore?.windowWidth"
        :speed="800"
        :spaceBetween="
          mainStore?.windowWidth >= 1920
            ? (10.286458333333332 / 100) * mainStore?.windowWidth
            : mainStore?.windowWidth < 1920 && mainStore?.windowWidth >= 1440
            ? (13.715277777777779 / 100) * mainStore?.windowWidth
            : mainStore?.windowWidth < 1440 && mainStore?.windowWidth > 983
            ? (19.10569105691057 / 100) * mainStore?.windowWidth
            : mainStore?.windowWidth < 984
            ? (17.349137931034484 / 100) * mainStore?.windowWidth
            : 0
        "
        :grabCursor="true"
        :loop="true"
        :updateOnWindowResize="true"
      >
        <SwiperSlide
          v-if="!props?.images"
          v-for="(item, index) in contentData?.data?.list?.[0]?.image"
          :key="index"
        >
          <div class="gumbati-slider__card">
            <Picture
              :devices="item?.devices"
              imgClass="img-absolute"
              alt="apartment image"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide v-if="props?.images" v-for="(photo, index) in images" :key="index">
          <div class="gumbati-slider__card">
            <Picture :devices="photo?.devices" imgClass="img-absolute" alt="image" />
          </div>
        </SwiperSlide>
      </Swiper>
      <div
        @click.stop="swiperInstance.slidePrev()"
        class="gumbati-slider__prev pos-abs"
        :class="dragOn ? 'gumbati-slider__prev-drag' : ''"
      >
        <LeftArrowSVG />
      </div>
      <div
        @click.stop="swiperInstance.slideNext()"
        class="gumbati-slider__next pos-abs"
        :class="dragOn ? 'gumbati-slider__next-drag' : ''"
      >
        <RightArrowSVG />
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.gumbati-slider {
  &__card {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 0;
    margin-top: 19.230769230769234%;
    margin-bottom: 16.312997347480106%;
    padding-top: calc(100% / (690 / 486));
    transition: all 1.1s cubic-bezier(0.165, 0.84, 0.44, 1);
    @include maxq(desktop) {
      margin-top: 21%;
      margin-bottom: 18.25%;
    }
    @include maxq(desktop-sm) {
      margin-top: 28.875%;
      margin-top: 26.125%;
    }
    @include maxq(mobile) {
      margin-top: 26.923076923076923%;
      margin-bottom: 26.923076923076923%;
    }
    @include maxq(desktop-sm) {
      padding-top: calc(100% / (510 / 360));
    }
    @include maxq(mobile) {
      padding-top: calc(100% / (254 / 180));
    }

    @media (max-height: 800px) {
      // padding-top: 0;
      height: 100%;
      margin-top: 22.125%;
    }
  }
  .swiper {
    overflow: visible;
    width: 35.9375%;
    height: 100%;
    @include maxq(desktop) {
      width: 47.9375%;
    }
    @include maxq(desktop-sm) {
      width: 51.9375%;
    }
    @include maxq(mobile) {
      width: 57.2%;
    }
    &-wrapper {
      transition: transform 0.6s cubic-bezier(0.5, 1, 0.89, 1);
    }
    .swiper-slide--grabbed {
      .gumbati-slider__card {
        transform: scale(0.9) !important;
      }
    }
    .swiper-slide {
      width: 100%;
      height: 100%;
    }
    .swiper-slide-active {
      position: relative;
      .gumbati-slider__card {
        transform: scale(1.28);
        @include maxq(desktop-sm) {
          transform: scale(1.33);
        }
        @include maxq(mobile) {
          transform: scale(1.37);
        }
      }
    }
  }
  &__inner {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  &__prev {
    cursor: pointer;
    top: 46.7010309278%;
    left: 22.5%;
    transform: translateX(0);
    @include easeOut(500, transform);
    opacity: 1;
    @include easeOut(2000, opacity);
    &-drag {
      opacity: 0;
      @include easeOut(750, opacity);
    }

    @include hover {
      transform: translateX(-15%);
      @include easeOut(500, transform);
    }
    @include maxq(desktop) {
      left: 13.5%;
    }
    @include maxq(desktop-sm) {
      top: 45.5555555556%;
      left: 7%;
    }
    @include maxq(mobile) {
      top: 41.4438502674%;
      left: 4%;
    }
  }

  &__next {
    cursor: pointer;
    top: 46.7010309278%;
    right: 22.5%;
    transform: translateX(0);
    @include easeOut(500, transform);
    opacity: 1;
    @include easeOut(2000, opacity);

    &-drag {
      opacity: 0;
      @include easeOut(750, opacity);
    }
    @include hover {
      transform: translateX(15%);
      @include easeOut(500, transform);
    }
    @include maxq(desktop) {
      right: 13.5%;
    }
    @include maxq(desktop-sm) {
      top: 45.5555555556%;
      right: 7%;
    }
    @include maxq(mobile) {
      top: 41.4438502674%;
      right: 4%;
    }
  }
}
</style>
